<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs" v-on="on" @click="ExportExcel">
                <v-img
                        lazy-src="/assets/images/excel.png"
                        max-height="32"
                        max-width="32"
                        src="/assets/images/excel.png"
                ></v-img>
            </v-btn>
        </template>
        <span>Excel'e Aktar</span>
    </v-tooltip>
</template>

<script>
    import { json2excel } from 'js2excel'
    export default {
        name: "ExcelExport",
        props:["data","headers","file"],
        methods:{
            ExportExcel(){
                try {
                    var data=this.data;
                    json2excel({
                        data,
                        name: this.file
                    });
                } catch (e) {
                    console.error('export error');
                }
            },
        }
    }
</script>

<style scoped>

</style>