<template>
        <v-responsive column>

                <v-data-table
                        dense
                        :headers="headers"
                        :items="data"
                        :search="search"
                        :class="{mobile: isMobile}"
                        :sort-by="['P_Id']"
                        :sort-desc="[true]"
                        :itemsPerPage="50"
                >
                        <template v-slot:top>
                                <table-export :data="data" :headers="headers" file="IsletmeListesi" />
                        </template>
                <template slot="item" slot-scope="props">
                        <tr v-if="!isMobile">
                                <td>#{{ props.item.P_Id }}</td>
                                <td class="text-xs-left" style="font-weight: 700">{{ props.item.P_Name }}</td>
                                <td class="text-xs-left">

                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="red" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="GoogleMapOpen(props.item.P_Address)">
                                                                location_on
                                                        </v-icon>
                                                </template>
                                                <span>Google Haritalar İle Aç</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="green" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="SendWhatsAppMap(props.item.P_Address)">
                                                                phone
                                                        </v-icon>
                                                </template>
                                                <span>WhatsApp ile Gönder</span>
                                        </v-tooltip>
                                        {{ props.item.P_Address }}</td>
                                <td class="text-xs-left">{{ props.item.P_ContractPackage }}</td>
                                <td class="text-xs-left">{{ props.item.P_ContractPrice }}</td>
                                <td class="text-xs-left">
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="green" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="PhoneCaller(props.item.P_AutPhone)">
                                                                phone_iphone
                                                        </v-icon>
                                                </template>
                                                <span>Telefon : {{ props.item.P_AutPhone }}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" dark v-bind="attrs" v-on="on" :disabled="dialog" :loading="dialog" @click="Mailto(props.item.P_AutEmail)">
                                                                mail
                                                        </v-icon>
                                                </template>
                                                <span>Eposta : {{ props.item.P_AutEmail }}</span>
                                        </v-tooltip>
                                        {{ props.item.P_Authorized }}</td>
                                <td class="text-xs-left">
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="primary" v-bind="attrs" v-on="on">
                                                                edit
                                                        </v-icon>
                                                </template>
                                                <span>Düzenle</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                        <v-icon color="red" dark v-bind="attrs" v-on="on">
                                                                clear
                                                        </v-icon>
                                                </template>
                                                <span>Sil</span>
                                        </v-tooltip>

                                </td>
                        </tr>
                        <tr v-else>
                                <td>
                                        <ul class="flex-content">
                                                <li class="flex-item" data-label="Id">#{{ props.item.P_Id }}</li>
                                                <li class="flex-item" data-label="Tesis">{{ props.item.P_Header }}</li>
                                                <li class="flex-item" data-label="İp">{{ props.item.P_Host }}</li>
                                                <li class="flex-item" data-label="Port">{{ props.item.P_Port }}</li>
                                                <li class="flex-item" data-label="User">{{ props.item.P_Username }}</li>
                                                <li class="flex-item" data-label="Pass">{{ props.item.P_Password }}</li>
                                                <li class="flex-item" data-label="Data">{{ props.item.P_DB_Brand }}</li>
                                                <li class="flex-item" data-label="Kişi">{{ props.item.PlacePeoples }}</li>
                                                <li class="flex-item" data-label="Durum" v-if="time_ago(props.item.LastUpdate)">{{ time_ago(props.item.LastUpdate) }}</li>
                                                <li class="flex-item" data-label="Durum" style="color:red;" v-else>İşlem Yok</li>
                                                <li class="flex-item" data-label="İşlem"> <v-icon style="font-size:20px;color:green"
                                                                                                  :disabled="dialog"
                                                                                                  :loading="dialog"
                                                                                                  @click="PlaceUpdate(props.item)">edit</v-icon></li>
                                        </ul>
                                </td>
                        </tr>
                </template>
        </v-data-table>
        </v-responsive>
</template>

<script>
import TableExport from "../../components/exports/TableExport";
    export default {
        name: "Business",
            components: {TableExport},
            data() {
                    return {
                            loading:false,
                            dialog:false,
                            isMobile: false,
                            headers: [
                                    {
                                            text: '#Id',
                                            align: 'left',
                                            value: 'P_Id'
                                    },
                                    {
                                            text: 'İşletme',
                                            value: 'P_Name'
                                    },
                                    {
                                            text: 'Adres',
                                            value: 'P_Address'
                                    }, {
                                            text: 'Abonelik',
                                            value: 'P_ContractPackage'
                                    },
                                    {
                                            text: 'Ücret',
                                            value: 'P_ContractPrice'
                                    },
                                    {
                                            text: 'Yetkili',
                                            value: 'P_Authorized'
                                    },
                                    {
                                            text: 'İşlem',
                                            value: 'PlaceAction'
                                    },

                            ],
                    }
            },
            methods: {
                    onResize() {
                            if (window.innerWidth < 769)
                                    this.isMobile = true;
                            else
                                    this.isMobile = false;
                    },
                    PhoneCaller(tel){
                            window.location.href = 'tel:' + tel
                    },
                    Mailto(mail){
                            window.location.href = 'mailto:' + mail
                    },
                    GoogleMapOpen(address){
                            window.open("https://maps.google.com/?q=" + encodeURI(address));
                    },
                    SendWhatsAppMap(address){
                            var uri=encodeURI("https://maps.google.com/?q=" + encodeURI(address));
                            window.open("https://wa.me/?text="+ uri)
                    },
                    EditPlace(row){
                            return row
                    }
            },
            computed: {
                    data:{
                            get(){
                                    return this.$store.state.business.data
                            },
                            set(val){
                                    this.$store.business.commit("setBusinessData",val)
                            }
                    },
                    search(){
                            return this.$store.state.search;
                    }
            },

        mounted() {
                if(this.$store.state.isLogin) {
                        this.$store.state.PageTitle = "İşletmeler"
                        if (this.data.length < 1) {
                                this.$store.dispatch("BusinessListUpdate")
                        }
                }
        }
    }
</script>

<style scoped>
        .application {
                line-height:1.5;
        }
        .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
                font-size: 0.8rem;
                font-weight: 400;
        }
        .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
                height: 22px;
                padding: 1px 12px;
        }
        .theme--dark.v-icon.v-icon.v-icon--disabled {
                color: rgba(102 ,108, 150 , 0.52) !important;
        }
</style>