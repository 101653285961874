<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs" v-on="on" @click="ExportJson">
                <v-img
                        lazy-src="/assets/images/json.png"
                        max-height="32"
                        max-width="32"
                        src="/assets/images/json.png"
                ></v-img>
            </v-btn>
        </template>
        <span>JSON'a Aktar</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: "JsonExport",
        props:["data","headers",'file'],
        methods: {
            ExportJson() {
                var jsonData = this.data;
                let dataStr = JSON.stringify(jsonData);
                let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);

                let exportFileDefaultName = this.file +'.json';

                let linkElement = document.createElement('a');
                linkElement.setAttribute('href', dataUri);
                linkElement.setAttribute('download', exportFileDefaultName);
                linkElement.click();
            },
        }
    }
</script>

<style scoped>

</style>