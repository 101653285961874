var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-responsive',{attrs:{"column":""}},[_c('v-data-table',{class:{mobile: _vm.isMobile},attrs:{"dense":"","headers":_vm.headers,"items":_vm.data,"search":_vm.search,"sort-by":['P_Id'],"sort-desc":[true],"itemsPerPage":50},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('table-export',{attrs:{"data":_vm.data,"headers":_vm.headers,"file":"IsletmeListesi"}})]},proxy:true},{key:"item",fn:function(props){return [(!_vm.isMobile)?_c('tr',[_c('td',[_vm._v("#"+_vm._s(props.item.P_Id))]),_c('td',{staticClass:"text-xs-left",staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(props.item.P_Name))]),_c('td',{staticClass:"text-xs-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":"","disabled":_vm.dialog,"loading":_vm.dialog},on:{"click":function($event){return _vm.GoogleMapOpen(props.item.P_Address)}}},'v-icon',attrs,false),on),[_vm._v(" location_on ")])]}}],null,true)},[_c('span',[_vm._v("Google Haritalar İle Aç")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":"","disabled":_vm.dialog,"loading":_vm.dialog},on:{"click":function($event){return _vm.SendWhatsAppMap(props.item.P_Address)}}},'v-icon',attrs,false),on),[_vm._v(" phone ")])]}}],null,true)},[_c('span',[_vm._v("WhatsApp ile Gönder")])]),_vm._v(" "+_vm._s(props.item.P_Address))],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.P_ContractPackage))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.P_ContractPrice))]),_c('td',{staticClass:"text-xs-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":"","disabled":_vm.dialog,"loading":_vm.dialog},on:{"click":function($event){return _vm.PhoneCaller(props.item.P_AutPhone)}}},'v-icon',attrs,false),on),[_vm._v(" phone_iphone ")])]}}],null,true)},[_c('span',[_vm._v("Telefon : "+_vm._s(props.item.P_AutPhone))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","disabled":_vm.dialog,"loading":_vm.dialog},on:{"click":function($event){return _vm.Mailto(props.item.P_AutEmail)}}},'v-icon',attrs,false),on),[_vm._v(" mail ")])]}}],null,true)},[_c('span',[_vm._v("Eposta : "+_vm._s(props.item.P_AutEmail))])]),_vm._v(" "+_vm._s(props.item.P_Authorized))],1),_c('td',{staticClass:"text-xs-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v("Düzenle")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" clear ")])]}}],null,true)},[_c('span',[_vm._v("Sil")])])],1)]):_c('tr',[_c('td',[_c('ul',{staticClass:"flex-content"},[_c('li',{staticClass:"flex-item",attrs:{"data-label":"Id"}},[_vm._v("#"+_vm._s(props.item.P_Id))]),_c('li',{staticClass:"flex-item",attrs:{"data-label":"Tesis"}},[_vm._v(_vm._s(props.item.P_Header))]),_c('li',{staticClass:"flex-item",attrs:{"data-label":"İp"}},[_vm._v(_vm._s(props.item.P_Host))]),_c('li',{staticClass:"flex-item",attrs:{"data-label":"Port"}},[_vm._v(_vm._s(props.item.P_Port))]),_c('li',{staticClass:"flex-item",attrs:{"data-label":"User"}},[_vm._v(_vm._s(props.item.P_Username))]),_c('li',{staticClass:"flex-item",attrs:{"data-label":"Pass"}},[_vm._v(_vm._s(props.item.P_Password))]),_c('li',{staticClass:"flex-item",attrs:{"data-label":"Data"}},[_vm._v(_vm._s(props.item.P_DB_Brand))]),_c('li',{staticClass:"flex-item",attrs:{"data-label":"Kişi"}},[_vm._v(_vm._s(props.item.PlacePeoples))]),(_vm.time_ago(props.item.LastUpdate))?_c('li',{staticClass:"flex-item",attrs:{"data-label":"Durum"}},[_vm._v(_vm._s(_vm.time_ago(props.item.LastUpdate)))]):_c('li',{staticClass:"flex-item",staticStyle:{"color":"red"},attrs:{"data-label":"Durum"}},[_vm._v("İşlem Yok")]),_c('li',{staticClass:"flex-item",attrs:{"data-label":"İşlem"}},[_c('v-icon',{staticStyle:{"font-size":"20px","color":"green"},attrs:{"disabled":_vm.dialog,"loading":_vm.dialog},on:{"click":function($event){return _vm.PlaceUpdate(props.item)}}},[_vm._v("edit")])],1)])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }