<template>
    <div class="table-header-left">
    <div class="table-header-right">
        <excel-export :data="data" :headers="headers" :file="file" />
        <json-export :data="data" :headers="headers" :file="file" />
        <pdf-export :data="data" :headers="headers" :file="file"/>
    </div>
    </div>
</template>

<script>
    import ExcelExport from "./ExcelExport";
    import JsonExport from "./JsonExport";
    import PdfExport from "./PdfExport";
    export default {
        name: "TableExport",
        props:['data','headers','file'],
        components: {JsonExport, ExcelExport,PdfExport}
    }
</script>

<style scoped>
 .table-header-right {
     padding: 1em;
     display: flex;
     justify-content: flex-end;
 }
</style>